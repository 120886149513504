<template lang="pug">
#app(@contextmenu="onRightClick" @pointerdown="refreshSession()")
  slot

  screen-bsi(
    v-bind:books.sync="books"
    v-bind:topics.sync="topics"
    @loaded="onLoad"
  )
    router-view#view.scroll(v-if="loaded" :autoscroll="autoscrollActive")
    session-tracker(ref="sessionTracker" v-bind:status.sync="userStatus" :delay="120" @expire="onExpire")
</template>

<script>
import router from "./lib/router.js";

import DataSource from "./components/DataSource.vue";
import SessionTracker from "./components/SessionTracker.vue";

import ScreenBanq from "./screens/ScreenBanq.vue";
import ScreenBsi from "./screens/ScreenBsi.vue";

import {keyBy, sampleSize} from "lodash";


export default {
  name: "App",
  router,

  components: {
    DataSource,
    ScreenBanq,
    ScreenBsi,
    SessionTracker,
  },

  data() {
    return {
      books: [],
      topics: [],

      loaded: false,
      userStatus: null,

      slideshowBooks: [],
      displayedTopics: [],
    };
  },

  computed: {
    topicsById(){
      return keyBy(this.topics, "identifier");
    },

    booksById(){
      return keyBy(this.books, "identifier");
    },

    autoscrollActive(){
      return (this.userStatus == "absent")
    },
  },

  methods: {
    onRightClick(e){
      if(e.buttons == 3){ return; }
      if(e.shiftKey){ return; }
      e.preventDefault();
    },

    refreshSession(){
      let tracker = this.$refs.sessionTracker;
      return tracker?.touch();
    },

    onExpire(){
      this.slideshowBooks = this.randomSlideshowBooks();
      this.shuffleTopics();
      this.goHome();
    },

    goHome(){
      // Will reject if already at '/'
      this.$router.push("/").catch(e=>e)
    },

    randomSlideshowBooks() {
      let coll = this.books;
      coll = coll.filter((r) => r.cover)

      if (!coll.length) return [];

      let n = 30;

      let m = coll.length - n;
      let i = Math.floor(Math.random() * m);
      return coll.slice(i, i + 30);
    },

    shuffleTopics(){
      this.displayedTopics = sampleSize(this.topics, 100)
    },

    onLoad(){
      this.loaded = true;
      this.slideshowBooks = this.randomSlideshowBooks();
      this.shuffleTopics();
    },
  }
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

//-  Font
body { font-family: Work Sans, sans-serif; }

.flex {
  display: flex;
}

.float-left {
  float: left;
  margin-right: 1em;
}

table {
  td {
    padding: 0.1em 0.5em 0.1em 0em;
  }
}

.button {
  font-size: 1.1em;
  padding: 0.4em 1.2em;
  border-radius: 3px;
  border: 0;
  cursor: pointer;

  background: hsl(210, 20%, 50%);
  color: white;
}

nav.back { font-size: 0.9em; margin-bottom: 2.0rem; }

a.back {
  padding: 0.4em 0.8em; border-radius: 0.3em;
  border: 1px solid #ffffff66;
}


.section {
  margin: 1em 0;
}

.container { max-width: 40rem; margin: auto; }

// Especially on Windows
.scroll { scrollbar-width: none; overflow-y: auto;}
.scroll::-webkit-scrollbar { display: none; }
</style>
